import React from "react";

import { graphql } from "gatsby";

import SearchPage from "../../components/pages/Search";

const SucheIndexPage = () => <SearchPage slug={null} />;

export default SucheIndexPage;

export const query = graphql`
    query {
        page: pagesYaml(templateKey: { eq: "pages/suche" }) {
            ...Page
        }
    }
`;
